const typesArray = [
    'Alligator-Person',
    'Alphabetrian',
    'Amoeba-Person',
    'Animal',
    'Anime',
    'Artificial Intelligence',
    'Bepisian',
    'Bird-Person Human Mix',
    'Bird-Person',
    'Blue ape alien',
    'Boobie buyer reptilian',
    'Boobloosian',
    'Bread',
    'Cat controlled dead lady',
    'Cat-Person',
    'Cat',
    'Caterpillar',
    'Centaur',
    'Chair',
    'Changeformer',
    'CHUD Human Mix',
    'CHUD',
    'Clay-Person',
    'Clone',
    'Cone-nippled alien',
    'Conjoined twin',
    'Cookie',
    'Corn-person',
    'Cromulon',
    'Cronenberg',
    'Crow Horse',
    'Crow',
    'Cyborg',
    'Decoy',
    'Demon',
    'Dog',
    'Doopidoo',
    'Dragon',
    'Drumbloxian',
    'Dummy',
    'Eat shiter-Person',
    'Eel',
    'Elephant-Person',
    'Ferkusian',
    'Ferret Robot',
    'Fish-Person',
    'Flansian',
    'Floop Floopian',
    'Fly',
    'Game',
    'Garblovian',
    'Gazorpian reproduction robot',
    'Gazorpian',
    'Gear-Person',
    'Genetic experiment',
    'Giant Cat Monster',
    'Giant Incest Baby',
    'Giant',
    'Glorzo',
    'God',
    'Goddess',
    'Gramuflackian',
    'Grandma',
    'Greebybobe',
    'Gromflomite',
    'Guinea Pig for the Polio Vaccine',
    'Hairy alien',
    'Half Soulless Puppet',
    'Hammerhead-Person',
    'Hivemind',
    'Hole',
    'Hologram',
    'Human Gazorpian',
    'Human with a flower in his head',
    'Human with antennae',
    'Human with ants in his eyes',
    'Human with baby legs',
    'Human with giant head',
    'Human with tusks',
    'Human-Snake hybrid',
    'Interdimensional gaseous being',
    'Jellybean',
    'Korblock',
    'Krootabulan',
    'Larva alien',
    'Leprechaun',
    'Light bulb-Alien',
    'Little Human',
    'Lizard-Person',
    'Lizard',
    'Lobster-Alien',
    'Mannie',
    'Mascot',
    'Meeseeks',
    'Mega Gargantuan',
    'Memory',
    'Mexican',
    'Microverse inhabitant',
    'Miniverse inhabitant',
    'Monogatron',
    'Monster',
    'Morglutzian',
    'Mytholog',
    'Nano Alien',
    'Narnian',
    'Necrophiliac',
    'Normal Size Bug',
    'Numbericon',
    'Octopus-Person',
    'Old Amazons',
    'Omniscient being',
    'Organic gun',
    'Parasite',
    'Passing Butter Robot',
    'Phone-Person',
    'Phone',
    'Pickle',
    'Pizza',
    'Planet',
    'Plutonian',
    'Pripudlian',
    'Rat',
    'Ring-nippled alien',
    'Robot-Crocodile hybrid',
    'Robot',
    'Scarecrow',
    'Scrotian',
    'Self-aware arm',
    'Sentient ant colony',
    'Sexy Aquaman',
    'Shapeshifter',
    'Shimshamian',
    'Shrimp',
    'Slartivartian',
    'Slug',
    'Snail alien',
    'Snake',
    'Soulless Puppet',
    'Squid',
    'Stair goblin',
    'Starfish',
    'Summon',
    'Super Sperm Monster',
    'Superhuman (Ghost trains summoner)',
    'Superhuman',
    'Teddy Bear',
    'Teenyverse inhabitant',
    'Tentacle alien',
    'The Devil',
    'Tiger',
    'Time God',
    'Tinymouth',
    'Toy',
    'Traflorkian',
    'Trunk-Person',
    'Tumblorkian',
    'Turkey Human Mix',
    'Turkey',
    'Tuskfish',
    'Unknown-nippled alien',
    'Vampire',
    'Wasp',
    'Weasel',
    'Whenwolf',
    'Zeus',
    'Zigerion',
    'Zombodian',
    "Morty's toxic side",
    "Rick's toxic side",
];

export default typesArray